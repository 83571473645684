.ProfileSlider {
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* text-align: center; */
  font-size: 18px;
  background-color: #fff;
}

.swiper-button-next,
.swiper-button-prev {
  color: #000;
}

.challenge-box h1 {
  font-size: 22px;
  font-weight: 600;
  color: #000;
  text-transform: none;
  margin: 0 0 20px;
  padding: 0;
  border-radius: 10px;
}

.challenge-shorts .shorts > .react-thumbnail-generator {
  width: 139px;
  height: 248px;
  overflow: hidden;
  border-radius: 10px;
}

.challenge-shorts .shorts {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 10px;
}

.challenge-shorts .shorts .overlay {
  background-color: #000;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
  border-radius: 10px;
}

.challenge-shorts .shorts .play-btn {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.challenge-shorts .shorts .play-btn img {
  width: 30px;
}

.challenge-shorts .rating {
  margin: 10px 0 5px;
  float: left;
  width: 100%;
}

.challenge-shorts .rating i {
  color: #fed144;
  font-size: 18px;
  margin: 0 -1px;
}

.challenge-shorts .rating .count {
  font-size: 14px;
  color: var(--m-black);
  margin-left: 5px;
}

.challenge-shorts .love,
.challenge-shorts .like {
  border-radius: 3px;
  padding: 3px 5px;
  margin-right: 5px;
  border: #e0e0e0 solid 1px;
  font-size: 13px;
  color: var(--m-black);
}

.challenge-shorts .badge {
  border-radius: 3px;
  padding: 3px 5px;
  margin-right: 5px;
  border: #e0e0e0 solid 1px;
  font-size: 13px;
  font-weight: 400;
  color: var(--m-black);
  margin-top: 10px;
}

.pending {
  border-radius: 3px;
  padding: 3px 5px;
  border: #e0e0e0 solid 1px;
  font-size: 13px;
  font-weight: 400;
  color: var(--m-black);
  margin-top: 10px;
  display: inline-block;
}

.pending i {
  font-size: 18px;
  margin: 0 -1px;
}

.play-btn {
  cursor: pointer;
}

.challenges-title > span {
  display: inline !important;
  margin-left: 15px;
}

.elements > span > img {
  margin-top: -2px;
  margin-right: 2px;
  width: 15px;
}

.feeds-container {
  height: 420px;
  overflow-y: auto;
}